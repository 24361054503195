import getConfig from 'next/config';
import {Provider as AuthProvider} from 'next-auth/client';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {ApolloProvider, useApolloClient} from '@apollo/client';
import {CssBaseline} from 'components/mui';
import {LicenseInfo} from '@mui/x-license-pro';
import {StylesProvider} from '@mui/styles';
import {ThemeProvider as StyledComponentsThemeProvider} from 'styled-components';
import {
    ThemeProvider as MuiThemeProvider,
    StyledEngineProvider,
} from '@mui/material/styles';
import client from 'components/apollo/client';
import initCache from 'components/apollo/initCache';
import {NotificationsProvider} from 'components/providers/NotificationsProvider';
import theme from 'styles/theme/theme';
import {AppProps} from 'next/app';
import {Session} from 'next-auth';
import useTracking from 'components/tracking/useTracking';

LicenseInfo.setLicenseKey(getConfig().publicRuntimeConfig.materialUILicenseKey);

type ApolloCacheWrapperTypes = {children: React.ReactNode};

function ApolloCacheWrapper({children}: ApolloCacheWrapperTypes) {
    const apolloClient = useApolloClient();

    useEffect(() => {
        initCache(apolloClient);
    }, [apolloClient]);

    return <>{children}</>;
}

function SDP({Component, pageProps}: AppProps<{session: Session}>) {
    useTracking();

    return (
        <ApolloProvider client={client}>
            <AuthProvider session={pageProps.session}>
                <StyledEngineProvider injectFirst>
                    <MuiThemeProvider theme={theme}>
                        <StylesProvider injectFirst>
                            <CssBaseline />
                            <StyledComponentsThemeProvider theme={theme}>
                                <ApolloCacheWrapper>
                                    <NotificationsProvider>
                                        <Component {...pageProps} />
                                    </NotificationsProvider>
                                </ApolloCacheWrapper>
                            </StyledComponentsThemeProvider>
                        </StylesProvider>
                    </MuiThemeProvider>
                </StyledEngineProvider>
            </AuthProvider>
        </ApolloProvider>
    );
}

SDP.propTypes = {
    Component: PropTypes.func.isRequired,
    pageProps: PropTypes.object.isRequired,
};

export default SDP;
